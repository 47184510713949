/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

import store from '@/store'

export const getCategoryById = (state, getters, rootState) => id => {
  const checkType = rootState.estimate.type

  return state.items.find(
    item => item.showTypeIds.includes(checkType) && item.id === id
  )
}

export const getCategoriesByParentId = (
  state,
  getters,
  rootState
) => parent => {
  const checkType = rootState.estimate.type
  return state.items
    .filter(
      item => item.showTypeIds.includes(checkType) && item.parent === parent
    )
    .sort(_objSort)
}

export const hasChildCategoriesById = (state, getters, rootState) => id => {
  const checkType = rootState.estimate.type

  return (
    state.items.filter(
      item => item.showTypeIds.includes(checkType) && item.parent === id
    ).length > 0
  )
}

export const getRootCategoryById = (state, getters, rootState) => id => {
  const checkType = rootState.estimate.type
  const recursive = id => {
    const category = state.items.find(
      item => item.showTypeIds.includes(checkType) && item.id === id
    )
    if (category.parent === '0') return category
    return recursive(category.parent)
  }

  return recursive(id)
}

export const getParentListById = (state, getters, rootState) => id => {
  if (!id) return []

  const recursive = ids => {
    const category = store.getters['category/getCategoryById'](
      ids[ids.length - 1]
    )
    if (category.parent === '0') return ids
    ids.push(category.parent)
    return recursive(ids)
  }
  return recursive([id])
}

const _objSort = (a, b) => {
  return +a.sortNum > +b.sortNum ? 1 : -1
}

export default {
  getCategoryById,
  getCategoriesByParentId,
  hasChildCategoriesById,
  getRootCategoryById,
  getParentListById,
}

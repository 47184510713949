/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

export const getOptionById = state => id => {
  return state.items.find(item => item.id === id);
};

export const getOptionsByIds = state => ids => {
  return ids.reduce((curry, id) => {
    curry.push(state.items.find(item => item.id === id));
    return curry;
  }, []);
};

export default {
  getOptionById,
  getOptionsByIds
};

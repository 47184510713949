/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import Vue from 'vue'
import store from '@/store'
import * as types from './mutation-types'

export const initEstimate = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    commit(types.INIT_ESTIMATE)
    resolve()
  })
}

// 戸建て or マンション
export const checkType = ({ commit }, type) => {
  commit(types.CHECK_TYPE, type)
}

export const addItem = ({ commit }, payload) => {
  commit(types.ADD_ITEM, payload)
}

export const tmpTotal = ({ commit }, payload) => {
  commit(types.TMP_TOTAL, payload)
}

export const setSiteId = ({ commit }, payload) => {
  commit(types.SET_SITE_ID, payload)
}

export const setForm = ({ commit }, payload) => {
  commit(types.SET_FORM, payload)
}

export const clearForm = ({ commit }) => {
  commit(types.SET_FORM, null)
}

export const clearEstimate = ({ commit }) => {
  commit(types.CLEAR_ESTIMATE, null)
}

export const fetchPreLoadItems = ({ commit }, payload) => {
  const ids = Array.from(
    new Set(store.getters['estimate/getPreLoadProductIds'])
  )

  const fetchProducts = ids.map(id =>
    store.dispatch('product/fetchProductById', { id })
  )

  const fetchOptions = ids.map(id =>
    store.dispatch('option/fetchOptionsByProductId', { id })
  )

  return Promise.all([...fetchProducts, ...fetchOptions])
}

export const renameHistory = ({ commit }, payload) => {
  commit(types.RENAME_HISTORY, payload)
}

export const updateHistory = ({ commit }, payload) => {
  commit(types.UPDATE_HISTORY, payload)
}

export const addHistory = ({ commit }, payload) => {
  commit(types.ADD_HISTORY, payload)
}

export const callHistory = ({ commit }, payload) => {
  commit(types.CALL_HISTORY, payload)
}

export const removeHistory = ({ commit }, payload) => {
  commit(types.REMOVE_HISTORY, payload)
}

export const removeCategory = ({ commit }, payload) => {
  commit(types.REMOVE_CATEGORY, payload)
}

export const removeProduct = ({ commit }, payload) => {
  commit(types.REMOVE_PRODUCT, payload)
}

export default {
  addItem,
  checkType,
  initEstimate,
  setSiteId,
  setForm,
  clearForm,
  clearEstimate,
  fetchPreLoadItems,
  renameHistory,
  updateHistory,
  addHistory,
  removeHistory,
  callHistory,
  removeCategory,
  removeProduct,
  tmpTotal,
}

/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import Vue from "vue";
import store from "@/store";
import axios from "axios";
import * as types from "./mutation-types";

export const fetchProductById = ({ commit, state, rootState }, payload) => {
  return new Promise((resolve, reject) => {
    // 取得済み
    if (state.items.find(item => item.id === payload.id)) {
      resolve();
      return;
    }

    const site = rootState.estimate.site;
    axios.get(`/products?site=${site}&product=${payload.id}`).then(response => {
      commit(types.FETCH_PRODUCTS, response.data);
      resolve();
    });
  });
};

export const fetchProductsByCategoryId = (
  { commit, state, rootState },
  payload
) => {
  return new Promise((resolve, reject) => {
    const site = rootState.estimate.site;
    axios
      .get(`/products?site=${site}&category=${payload.category}`)
      .then(response => {
        commit(types.FETCH_PRODUCTS, response.data);
        resolve();
      });
  });
};

export default {
  fetchProductById,
  fetchProductsByCategoryId
};

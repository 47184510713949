/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  site: null,
  type: null,
  items: {},
  form: null,
  history: [],
  tmp: {},
  selected: [],
}

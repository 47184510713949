/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

export const getMakerById = state => id => {
  return state.items.find(item => item.id === id);
};

export default {
  getMakerById
};

/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
// export const REMOVE_ITEM = "REMOVE_ITEM";

export default {
  FETCH_PRODUCTS
  // REMOVE_ITEM
};

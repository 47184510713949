/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import Vue from "vue";
import store from "@/store";
import axios from "axios";
import * as types from "./mutation-types";

export const initMakers = ({ commit, state }, payload) => {
  return new Promise((resolve, reject) => {
    if (state.items.length > 0) {
      resolve();
      return;
    }

    axios.get("/makers").then(response => {
      commit(types.INIT_MAKERS, response.data);
      resolve();
    });
  });
};

export default {
  initMakers
};

/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/routes";
import store from "@/store";

Vue.use(VueRouter);

export const router = new VueRouter({
  routes
});

// グローバルガード
router.beforeEach((to, from, next) => {
  $('.nshmRfsys__outer').addClass('loading');
  Promise.resolve()
    .then(() => store.dispatch("master/initMaster"))
    .then(() => store.dispatch("estimate/initEstimate"))
    .then(() =>
      Promise.all([
        store.dispatch("category/initCategories"),
        store.dispatch("maker/initMakers"),
        store.dispatch("estimate/fetchPreLoadItems")
      ])
    )
    .then(() => {
      if (
        to.matched.some(m => m.meta.isCheckedType) &&
        !store.state.estimate.type
      ) {
        next({ name: "home.index" });
      }
      next();
    });
});

// スクロールをトップに戻す
router.afterEach((to, from) => {
  $('.nshmRfsys__outer').removeClass('loading');
  window.scrollTo(0, 0);
});

Vue.router = router;

export default {
  router
};

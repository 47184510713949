/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */

export default [
  {
    path: "/",
    name: "home.index",
    component: () => import("@/pages/Home/Index.vue")
  },

  {
    path: "/category",
    name: "category.index",
    meta: { isCheckedType: true },
    component: () => import("@/pages/Category/Index.vue")
  },

  {
    path: "/category/:category",
    name: "category.sub",
    meta: { isCheckedType: true },
    component: () => import("@/pages/Category/Sub.vue")
  },

  {
    path: "/list/:category",
    name: "products.list",
    meta: { isCheckedType: true },
    component: () => import("@/pages/Products/List.vue")
  },

  {
    path: "/detail/:product",
    name: "products.detail",
    meta: { isCheckedType: true },
    component: () => import("@/pages/Products/Detail.vue")
  },

  {
    path: "/result",
    name: "result.index",
    meta: { isCheckedType: true },
    component: () => import("@/pages/Result/Index.vue")
  },

  {
    path: "/result/confirm",
    name: "result.confirm",
    meta: { isCheckedType: true },
    component: () => import("@/pages/Result/Confirm.vue")
  },

  {
    path: "/result/complete",
    name: "result.complete",
    meta: { isCheckedType: true },
    component: () => import("@/pages/Result/Complete.vue")
  },

  {
    path: "/estimate",
    name: "estimate.index",
    meta: { isCheckedType: true },
    component: () => import("@/pages/Estimate/Index.vue")
  },

  // {
  //   path: "/",
  //   redirect: "/home"
  // },

  {
    path: "/*",
    redirect: "/"
  }
];

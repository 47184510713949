/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import Vue from 'vue'
import { INIT_MASTER, SET_THEME, SET_DESCRIPTION } from './mutation-types'

/* eslint-disable no-param-reassign */
export default {
  [INIT_MASTER](state, payload) {
    state.types = payload.types
    state.prefList = payload.prefList
    state.taxRate = payload.taxRate
    state.theme = payload.theme
    state.maxHistory = payload.maxHistory
  },
  [SET_THEME](state, payload) {
    state.theme = payload
  },
  [SET_DESCRIPTION](state, payload) {
    state.description = payload
  },
}

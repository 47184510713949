/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */
import _ from 'lodash'

export const getProductById = (state, getters, rootState) => id => {
  const checkType = rootState.estimate.type

  return state.items.find(
    item => item.showTypeIds.includes(checkType) && item.id === id
  )
}

export const getProductsByCategoryId = (
  state,
  getters,
  rootState
) => category => {
  const checkType = rootState.estimate.type

  const tmp = state.items.filter(
    item => item.showTypeIds.includes(checkType) && item.category === category
  )
  return _.sortBy(tmp, ['sortNum', 'id'])
}

export default {
  getProductById,
  getProductsByCategoryId,
}

/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import Vue from "vue";
import { FETCH_PRODUCTS } from "./mutation-types";

/* eslint-disable no-param-reassign */
export default {
  [FETCH_PRODUCTS](state, payload) {
    // id の重複を削除
    state.items = [...state.items, ...payload].filter(
      (search, i, self) => self.findIndex(tmp => tmp.id == search.id) === i
    );
  }
};

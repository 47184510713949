/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

// Modules
import estimate from "./modules/estimate";
import category from "./modules/category";
import maker from "./modules/maker";
import master from "./modules/master";
import product from "./modules/product";
import option from "./modules/option";

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  /**
   * Assign the modules to the store.
   */
  modules: {
    estimate,
    category,
    maker,
    master,
    product,
    option
  },

  /**
   * If strict mode should be enabled.
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  plugins: debug ? [createLogger()] : []
});

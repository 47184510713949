/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const INIT_ESTIMATE = 'INIT_ESTIMATE'
export const CHECK_TYPE = 'CHECK_TYPE'
export const ADD_ITEM = 'ADD_ITEM'
export const SET_SITE_ID = 'SET_SITE_ID'
export const SET_FORM = 'SET_FORM'
export const SET_ESTIMATE = 'SET_ESTIMATE'
export const CLEAR_ESTIMATE = 'CLEAR_ESTIMATE'
export const RENAME_HISTORY = 'RENAME_HISTORY'
export const UPDATE_HISTORY = 'UPDATE_HISTORY'
export const ADD_HISTORY = 'ADD_HISTORY'
export const REMOVE_HISTORY = 'REMOVE_HISTORY'
export const CALL_HISTORY = 'CALL_HISTORY'
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const TMP_TOTAL = 'TMP_TOTAL'

export default {
  INIT_ESTIMATE,
  CHECK_TYPE,
  ADD_ITEM,
  SET_SITE_ID,
  SET_FORM,
  SET_ESTIMATE,
  CLEAR_ESTIMATE,
  RENAME_HISTORY,
  UPDATE_HISTORY,
  ADD_HISTORY,
  REMOVE_HISTORY,
  CALL_HISTORY,
  REMOVE_CATEGORY,
  REMOVE_PRODUCT,
  TMP_TOTAL,
}

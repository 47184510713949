/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const INIT_MAKERS = "INIT_MAKERS";
// export const REMOVE_ITEM = "REMOVE_ITEM";

export default {
  INIT_MAKERS
  // REMOVE_ITEM
};

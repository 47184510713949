/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const INIT_MASTER = 'INIT_MASTER'
export const SET_THEME = 'SET_THEME'
export const SET_DESCRIPTION = 'SET_DESCRIPTION'

export default {
  INIT_MASTER,
  SET_THEME,
  SET_DESCRIPTION,
}

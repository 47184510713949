/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

const items = [];

export default {
  type: null,
  items
};

/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */
import store from '@/store'

export const getCheckType = state => {
  return state.type
}

export const getSiteId = state => {
  return state.site
}

export const getHasItem = state => {
  const tmp = _getFlatList(state.items[state.type])
  return tmp.reduce((curry, item) => {
    return curry + item.items.length
  }, 0)
}

const _getFlatList = estimate => {
  const recursive = (original, curry) => {
    if (original === undefined) return curry

    return Object.keys(original).reduce((curry, key) => {
      if (original[key].hasOwnProperty('items')) {
        curry = [...curry, ...original[key].items]
      } else if (original[key].hasOwnProperty('child')) {
        curry = [...curry, ...recursive(original[key].child, curry)]
      }

      return curry
    }, [])
  }

  if (!estimate) return []
  return Object.keys(estimate).map(root => {
    const items = estimate[root].hasOwnProperty('items')
      ? estimate[root].items
      : []

    return {
      id: root,
      items: items.concat(recursive(estimate[root].child, [])),
    }
  })
}

export const getFlatList = state => {
  return _getFlatList(state.items[state.type])
}

const _getOptionSubTotal = options => {
  return options.reduce((curry, item) => {
    const option = store.getters['option/getOptionById'](item.id)
    const salePrice = option.salePrice ? +option.salePrice : 0
    const cCost = option.cCost ? +option.cCost : 0

    return curry + cCost + salePrice * +item.quantity
  }, 0)
}

const _getCategorySubTotal = (state, list) => {
  return list.map(root => {
    const products = root.items.map(item => {
      const product = store.getters['product/getProductById'](item.id)
      const options =
        state.tmp.id === item.id ? state.tmp.options : item.options

      const optionSubTotal = _getOptionSubTotal(options)

      const salePrice = product.salePrice ? +product.salePrice : 0
      const cCost = product.cCost ? +product.cCost : 0

      return {
        id: product.id,
        name: product.name,
        subTotal: cCost + salePrice + +optionSubTotal,
        tmp: state.tmp.id === item.id,
      }
    })

    const subTotal = products.reduce((curry, item) => {
      return curry + item.subTotal
    }, 0)

    const category = store.getters['category/getCategoryById'](root.id)

    return {
      id: root.id,
      subTotal,
      name: category.name,
      products,
    }
  })
}

export const getCategorySubTotal = state => {
  const flatList = _getFlatList(state.items[state.type])
  if (state.tmp.id) {
    const product = store.getters['product/getProductById'](state.tmp.id)
    const parent = store.getters['category/getParentListById'](product.category)
    const root = parent.pop()
    const index = flatList.findIndex(item => item.id === root)

    if (index >= 0) {
      const i = flatList[index].items.findIndex(
        item => item.id === state.tmp.id
      )
      if (i < 0) flatList[index].items.push({ tmp: true, ...state.tmp })
    } else {
      flatList.push({ id: root, items: [state.tmp] })
    }
  }

  return _getCategorySubTotal(state, flatList)
}

export const getPreLoadProductIds = state => {
  const reducer = (curry, rootCategory) => {
    const ids = rootCategory.items.reduce((curry, item) => {
      curry.push(item.id)
      return curry
    }, [])
    curry = curry.concat(ids)

    return curry
  }

  const tmpEstimate = _getFlatList(state.items[state.type])
  const tmpIds = tmpEstimate.reduce(reducer, [])

  const historyIds = Object.keys(state.history).reduce((curry, key) => {
    return state.history[key].reduce((curry, items) => {
      const ids = _getFlatList(items.items).reduce(reducer, [])
      return [...curry, ...ids]
    }, curry)
  }, [])

  return [...tmpIds, ...historyIds]
}

export const getForm = state => {
  return state.form
}

export const getItems = state => {
  return state.items[state.type]
}

export const getOptionsByProductId = (state, getters, rootState) => id => {
  const rootCategoryId = store.getters['category/getRootCategoryById'](
    store.getters['product/getProductById'](id).category
  ).id
  const rootCategory = _getFlatList(state.items[state.type]).find(
    item => item.id === rootCategoryId
  )

  if (!rootCategory) return null

  const product = rootCategory.items.find(item => item.id === id)

  return product ? product.options : null
}

export const getHistoryList = state => {
  return state.history[state.type].map(estimate => {
    const flatList = _getFlatList(estimate.items)
    const subTotal = _getCategorySubTotal(state, flatList).reduce(
      (curry, category) => {
        return curry + category.subTotal
      },
      0
    )
    return {
      name: estimate.name,
      subTotal,
    }
  })
}

export const getHistoryCount = state => {
  return state.history[state.type].length
}

export const getEstimateName = state => {
  if (state.selected[state.type] === null) return '新規見積り'
  return state.history[state.type][state.selected[state.type]].name
}

export const getType = state => {
  return state.type
}

export const getHistoryIndex = state => {
  return state.selected
}

export default {
  getCheckType,
  getSiteId,
  getHasItem,
  getForm,
  getItems,
  getCategorySubTotal,
  getFlatList,
  getPreLoadProductIds,
  getHistoryList,
  getHistoryCount,
  getType,
  getHistoryIndex,
  getOptionsByProductId,
  getEstimateName,
}

/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

export const getTypes = state => {
  return state.types
}

export const getDescription = state => {
  return state.description
}

export const getTypeById = state => id => {
  return state.types.find(type => type.id === id)
}

export const getPrefList = state => {
  return state.prefList
}

export const getTaxRate = state => {
  return state.taxRate
}

export const getTheme = state => {
  return state.theme
}

export const getMaxHistory = state => {
  return state.maxHistory
}

export default {
  getTypes,
  getTypeById,
  getPrefList,
  getTaxRate,
  getTheme,
  getDescription,
  getMaxHistory,
}

/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Vue from "vue";
import Axios from "axios";
import store from "@/store";

Axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;
Axios.defaults.headers.common.Accept = "application/json";
// Axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";
// Axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// Axios.interceptors.response.use(
//   response => response,
//   (error) => {
//     if (error.response.status === 401) {
//       store.dispatch('auth/logout');
//     }

//     return Promise.reject(error);
//   },
// );

// Axios.interceptors.request.use((request) => {
//   console.log("interceptors.request")
//   return request
// });

// Axios.interceptors.response.use((response) => {
//   console.log("interceptors.response")
//   return response
// });

// Bind Axios to Vue.
Vue.$http = Axios;
Object.defineProperty(Vue.prototype, "$http", {
  get() {
    return Axios;
  }
});

/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import Vue from 'vue'
import store from '@/store'
import moment from 'moment'

import {
  INIT_ESTIMATE,
  CHECK_TYPE,
  ADD_ITEM,
  SET_SITE_ID,
  SET_FORM,
  SET_ESTIMATE,
  CLEAR_ESTIMATE,
  RENAME_HISTORY,
  UPDATE_HISTORY,
  ADD_HISTORY,
  REMOVE_HISTORY,
  CALL_HISTORY,
  REMOVE_CATEGORY,
  REMOVE_PRODUCT,
  TMP_TOTAL,
} from './mutation-types'

/* eslint-disable no-param-reassign */
export default {
  [INIT_ESTIMATE](state, payload) {
    state.type = localStorage.getItem('checkType')

    const types = store.getters['master/getTypes'].map(item => item.id)
    state.items = types.reduce((curry, type) => {
      curry[type] = {}
      return curry
    }, {})
    state.history = types.reduce((curry, type) => {
      curry[type] = []
      return curry
    }, {})
    state.selected = types.reduce((curry, type) => {
      curry[type] = null
      return curry
    }, {})

    if (localStorage['tmpEstimate']) {
      state.items = JSON.parse(localStorage.getItem('tmpEstimate'))
    }
    if (localStorage['estimateList']) {
      const types = store.getters['master/getTypes'].map(item => item.id)
      const maxHistory = store.getters['master/getMaxHistory']

      const tmp = JSON.parse(localStorage.getItem('estimateList'))
      state.history = types.reduce((curry, type) => {
        curry[type] = tmp[type].slice(0, maxHistory)
        return curry
      }, {})
      localStorage.setItem('estimateList', JSON.stringify(state.history))
    }
    if (localStorage['selected']) {
      state.selected = JSON.parse(localStorage.getItem('selected'))
    }

    state.tmp = { id: null, options: null }
  },

  [CHECK_TYPE](state, type) {
    state.type = type
    localStorage.setItem('checkType', type)
  },

  [ADD_ITEM](state, payload) {
    const product = store.getters['product/getProductById'](payload.product)
    const ids = store.getters['category/getParentListById'](product.category)

    const recursive = tree => {
      const id = ids.pop()
      const category = store.getters['category/getCategoryById'](id)
      if (!tree.hasOwnProperty(id)) tree[id] = {}

      if (ids.length !== 0) {
        if (!tree[id].hasOwnProperty('child')) tree[id].child = {}
        if (category.selectType === '0') {
          const childId = ids.slice(-1)[0]
          const tmp = tree[id].child[childId]
          tree[id].child = {}
          tree[id].child[childId] = tmp ? tmp : {}
        }
        tree[id].child = { ...recursive(tree[id].child) }
      } else {
        if (category.selectType === '0' || !tree[id].hasOwnProperty('items')) {
          tree[id].items = []
        }
        tree[id].items = tree[id].items.filter(item => item.id !== product.id)
        tree[id].items.push({
          id: product.id,
          options: payload.options,
        })
      }
      return tree
    }

    state.items[state.type] = { ...recursive(state.items[state.type]) }

    localStorage.setItem('tmpEstimate', JSON.stringify(state.items))
    localStorage.setItem('selected', JSON.stringify(state.selected))
  },

  [SET_ESTIMATE](state, payload) {
    state.items[state.type] = payload
    localStorage.setItem('tmpEstimate', JSON.stringify(state.items))
    localStorage.setItem('selected', JSON.stringify(state.selected))
  },

  [CLEAR_ESTIMATE](state, payload) {
    state.items = {}
    state.selected[state.type] = null
    localStorage.setItem('selected', JSON.stringify(state.selected))
    localStorage.removeItem('tmpEstimate')
  },

  [SET_SITE_ID](state, payload) {
    state.site = payload
  },

  [SET_FORM](state, payload) {
    state.form = payload
  },

  [RENAME_HISTORY](state, payload) {
    const { i, name } = payload
    state.history[state.type][i].name = name
    localStorage.setItem('estimateList', JSON.stringify(state.history))
  },

  [UPDATE_HISTORY](state, payload) {
    state.history[state.type][state.selected[state.type]].items =
      state.items[state.type]
    localStorage.setItem('estimateList', JSON.stringify(state.history))
    state.items[state.type] = {}
    localStorage.setItem('tmpEstimate', JSON.stringify(state.items))
    localStorage.setItem('selected', JSON.stringify(state.selected))
  },

  [ADD_HISTORY](state, payload) {
    if (state.selected[state.type] !== null) {
      state.history[state.type][state.selected[state.type]].items =
        state.items[state.type]
    } else {
      const maxHistory = store.getters['master/getMaxHistory']
      const time = moment().format('YYYYMMDDHHmmss')
      if (state.history[state.type].length >= maxHistory) return
      state.history[state.type].push({
        name: `新規見積り${time}`,
        items: state.items[state.type],
      })
      state.selected[state.type] = state.history[state.type].length - 1
      localStorage.setItem('selected', JSON.stringify(state.selected))
    }
    // state.history[state.type] = state.history[state.type].slice(-5)
    localStorage.setItem('estimateList', JSON.stringify(state.history))

    localStorage.setItem('tmpEstimate', JSON.stringify(state.items))
    localStorage.setItem('selected', JSON.stringify(state.selected))
  },

  [CALL_HISTORY](state, payload) {
    state.items[state.type] = state.history[state.type][payload].items
    state.selected[state.type] = payload
    localStorage.setItem('tmpEstimate', JSON.stringify(state.items))
    localStorage.setItem('selected', JSON.stringify(state.selected))
  },

  [REMOVE_HISTORY](state, payload) {
    state.history[state.type].splice(payload, 1)
    if (state.selected[state.type] == payload) {
      state.selected[state.type] = null
      localStorage.setItem('selected', JSON.stringify(state.selected))
    }
    localStorage.setItem('estimateList', JSON.stringify(state.history))
  },

  [REMOVE_CATEGORY](state, payload) {
    //filter
    if (state.items[state.type].hasOwnProperty(payload)) {
      delete state.items[state.type][payload]
      state.items[state.type] = {
        ...state.items[state.type],
      }
      localStorage.setItem('tmpEstimate', JSON.stringify(state.items))
      localStorage.setItem('selected', JSON.stringify(state.selected))
    }
  },

  [REMOVE_PRODUCT](state, payload) {
    //filter
    const product = store.getters['product/getProductById'](payload)
    const categories = store.getters['category/getParentListById'](
      product.category
    ).reverse()

    const recursive = (tree, categories) => {
      const id = categories.shift()
      if (categories.length > 0) {
        tree[id].child = recursive(tree[id].child, categories)
      } else {
        tree[id].items = tree[id].items.filter(item => {
          item.id != product.category
        })
      }
      return tree
    }
    state.items[state.type] = {
      ...recursive(state.items[state.type], categories),
    }
    localStorage.setItem('tmpEstimate', JSON.stringify(state.items))
    localStorage.setItem('selected', JSON.stringify(state.selected))
  },

  [TMP_TOTAL](state, payload) {
    state.tmp = {
      id: payload.product,
      options: payload.options,
    }
  },
}

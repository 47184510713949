/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const FETCH_OPTIONS = "FETCH_OPTIONS";
// export const REMOVE_ITEM = "REMOVE_ITEM";

export default {
  FETCH_OPTIONS
  // REMOVE_ITEM
};

/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  type: null,
  types: [],
  taxRate: 0,
  theme: null,
  description: '',
  maxHistory: 5,
}

<template>
  <div class="nshmRfsys__outer">
    <router-view/>
  </div>
</template>

<style src='./assets/css/front.css'></style>

<script>

export default {
  name: 'VueBoilerplate',

  data() {
    return {
      isLoad: false,
    }
  },

  beforeCreate() {
  },

  mounted() {
    require('./assets/js/jquery-2.2.4.min.js')
    var $224 = $.noConflict(true)
    // require('./assets/js/jquery.mCustomScrollbar')
    require('./assets/js/common.js')
  },
};
</script>

/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import Vue from 'vue'
import store from '@/store'
import axios from 'axios'
import * as types from './mutation-types'

export const initMaster = ({ commit, state }, payload) => {
  return new Promise((resolve, reject) => {
    if (state.types.length > 0) {
      resolve()
      return
    }

    axios.get('/master').then(response => {
      commit(types.INIT_MASTER, response.data)

      const sites = response.data.sites
      const site = sites.find(item => item.fqdn === location.hostname)
      store.dispatch('estimate/setSiteId', site.id)

      commit(types.SET_THEME, site.theme)
      commit(types.SET_DESCRIPTION, site.description)

      resolve()
    })
  })
}

export default {
  initMaster,
}
